import {EditorReadyFn, FlowEditorSDK} from '@wix/yoshi-flow-editor'
import {} from '@wix/platform-editor-sdk'

let sdk: FlowEditorSDK
let appToken: string
// let t: Function
let appDefinitionId

const getAppPages = async () => {
  const [{applicationId}, allPages] = await Promise.all([
    sdk.tpa.app.getDataByAppDefId(appToken, appDefinitionId),
    sdk.pages.data.getAll(appToken),
  ])

  return allPages.filter(({tpaApplicationId}) => tpaApplicationId === applicationId)
}

const ensurePagesManaged = async () => {
  const pages = await getAppPages()

  pages.forEach(({id, type}) => {
    const data = {
      isLandingPage: true,
      isMobileLandingPage: true,
      hidePage: true,
    }

    // @ts-expect-error
    sdk.pages.data.update(appToken, {pageRef: {id, type}, data})
  })
}

export const editorReady: EditorReadyFn = async (editorSDK, appDefId, platformOptions, flowAPI) => {
  // t = (await flowAPI.translations.init()).t
  sdk = editorSDK
  appDefinitionId = appDefId
  appToken = appDefinitionId

  await ensurePagesManaged()
}

export const handleAction = async (args: any) => {
  const {type, payload} = args
  switch (type) {
    case 'appInstalled':
      if (payload.appDefinitionId === appDefinitionId) {
        await ensurePagesManaged()
      }
      break
    default:
      break
  }
}
